const chargeBackFields = [
  {
    key: "client_name",
    label: "CLIENT",
    visible: true,
    sortable: false,
  },
  {
    key: "amount",
    label: "TRANSACTION",
    visible: true,
    sortable: false,
  },
  {
    key: "merchant",
    label: "MERCHANT",
    visible: true,
    sortable: false,
  },
  {
    key: "reason_code",
    label: "REASON CODE",
    visible: true,
    sortable: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "time_assign_responsible",
    label: "TIME TO ASSIGN",
    visible: true,
    sortable: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "time_upload_files",
    label: "TIME UPLOAD FILES",
    visible: true,
    sortable: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "department",
    label: "DEPARTMENT",
    visible: true,
    sortable: false,
  },
  {
    key: "created_at",
    label: "CREATED DATE",
    visible: true,
    sortable: true,
  },
  {
    key: "files",
    label: "FILES",
    visible: true,
    sortable: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "status",
    label: "STATUS",
    visible: true,
    sortable: false,
  },
  {
    key: "dispute_response",
    label: "REPLY",
    visible: true,
    sortable: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "days",
    label: "DAYS",
    visible: true,
    sortable: false,
  },
  {
    key: "observation",
    label: "OBSERVATION",
    visible: true,
    sortable: false,
  },
  {
    key: "status_penalty",
    label: "PENALTY",
    visible: true,
    sortable: false,
  },
  {
    key: "limit_time_payments",
    label: "PAYMENT TIME LIMIT",
    visible: true,
    sortable: true,
  },
  {
    key: "paid",
    label: "PAID",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
    sortable: false,
  },
  {
    key: "discounts",
    label: "DISCOUNTS FOR DEP.",
    visible: true,
    sortable: false,
  },
  {
    key: "status_recovered",
    label: "STATUS RECOVERED",
    visible: false,
    sortable: false,
  },
  {
    key: "type_chargeback",
    label: "SOLUTION",
    visible: false,
    sortable: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "tracking",
    label: "Tracking",
    visible: true,
    sortable: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "action",
    label: "ACTION",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
    sortable: false,
  },
]

export default chargeBackFields
