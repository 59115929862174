<template>
  <div>
    <filter-slot
      :filter="filter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="refreshTableChargebacks"
      @reset-all-filters="resetAllFilters"
    >
      <b-table
        slot="table"
        id="charge-back"
        ref="chargeBack"
        class="position-relative"
        :fields="visibleFields"
        show-empty
        no-provider-filtering
        sticky-header="50vh"
        primary-key="id"
        table-class="text-nowrap"
        responsive="sm"
        :items="chargeBackProvider"
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
        :filter="searchInput"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #cell(client_name)="data">
          <b-row>
            <b-col>
              <view-client-details
                v-if="isCreditExperts"
                :client-information="data.item"
              />
              <router-link
                v-else
                target="_blank"
                :to="{
                  name: dashboardRouteName,
                  params: { idClient: `${data.item.client_account_id}` },
                }"
              >
                {{ data.item.client_name }}
              </router-link>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              {{ data.item.client_account }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <status-account
                :account="data.item"
                :text="true"
              ></status-account>
            </b-col>
          </b-row>
        </template>

        <template #cell(amount)="data">
          <div>ID: {{ data.item.t_transaction_id }}</div>
          <div>$ {{ data.item.amount | currency }}</div>
          <div>{{ data.item.transaction_date | myGlobalDay }}</div>
        </template>

        <template #cell(merchant)="data">
          <span :class="defineColorMerchant(data.item.merchant_id)">
            {{ data.item.merchant_name }}
          </span>
          <div>AUTH CODE: {{ data.item.auth_code }}</div>
        </template>

        <template #cell(reason_code)="data">
          {{ data.item.reason_code }}
          <feather-icon
            icon="InfoIcon"
            class="text-primary"
            v-b-tooltip.hover.top="data.item.reason_code_description"
          />
        </template>

        <template #cell(time_assign_responsible)="data">
          <Timer
            v-if="
              data.item.time_assign_responsible > 0 &&
              data.item.time_assign_responsible <=
                limitTimeFile(data.item.limit_time_assign) &&
              data.item.time_expired_assign == 1
            "
            :time="data.item.time_assign_responsible"
            :chargeBackId="data.item.id"
            @expired="changeExpired(data.item)"
          />
          <b-badge v-else variant="light-danger" pill> EXPIRED </b-badge>
        </template>

        <template #cell(time_upload_files)="data">
          <Timer
            v-if="
              data.item.time_upload_files > 0 &&
              data.item.time_upload_files <=
                limitTimeFile(data.item.limit_time_files) &&
              data.item.time_expired.toLowerCase() == 'pending'
            "
            :time="data.item.time_upload_files"
            :chargeBackId="data.item.id"
            @expired="changeExpired(data.item)"
          />
          <b-badge v-else variant="light-danger" pill> EXPIRED </b-badge>
        </template>

        <template #cell(limit_time_payments)="data">
          <Timer
            v-if="
              data.item.limit_time_payments > 0 &&
              data.item.time_expired_payment == 1 &&
              data.item.paid != 2 &&
              data.item.paid != 5
            "
            :time="data.item.limit_time_payments"
            :chargeBackId="data.item.id"
            @expired="changeExpired(data.item)"
          />
          <b-badge v-else variant="light-danger" pill> EXPIRED </b-badge>
        </template>

        <template #cell(department)="data">
          {{ data.item.department }}
        </template>

        <template #cell(created_at)="data">
          {{ data.item.user_name }}
          <br />
          {{ data.item.created_at | myGlobalWithHour }}
        </template>

        <template #cell(files)="data">
          <feather-icon
            :class="
              'pointer text-' +
              defineFileColor(
                data.item.total_pending,
                data.item.total_approved,
                data.item.total_rejected
              )
            "
            icon="FileTextIcon"
            size="18"
            v-b-tooltip.hover.top="
              defineFileTooltip(
                data.item.total_pending,
                data.item.total_approved,
                data.item.total_rejected
              )
            "
            @click="modalEvidences(data.item)"
          />
        </template>

        <template #cell(status)="data">
          {{ data.item.status }}
        </template>

        <template #cell(dispute_response)="{ item, value }">
          <b-badge
            style="text-transform: uppercase"
            :variant="
              value == 'pending'
                ? 'light-warning'
                : value == 'approved'
                ? 'light-success'
                : 'light-danger'
            "
          >
            {{ item.dispute_response }}
          </b-badge>
        </template>

        <template #cell(days)="{ item }">
          <b-badge
            :variant="
              item.date_passed > 20
                ? 'light-danger'
                : item.date_passed > 10
                ? 'light-warning'
                : 'light-success'
            "
          >
            <span v-if="item.date_passed > 30"> EXPIRED </span>
            <span v-else>
              {{ item.date_passed }}
            </span>
          </b-badge>
        </template>

        <template #cell(observation)="data">
          {{ data.item.observation }}
        </template>

        <template #cell(status_penalty)="data">
          <b-badge
            :variant="
              data.item.status_penalty == 1
                ? 'light-success'
                : data.item.status_penalty == 2
                ? 'light-danger'
                : 'light-warning'
            "
            >{{
              data.item.status_penalty == 1
                ? "ACCEPT"
                : data.item.status_penalty == 2
                ? "DENIED"
                : "NOT ASSIGNED"
            }}</b-badge
          >
        </template>

        <template #cell(paid)="data">
          <feather-icon
            v-if="data.item.paid == 1"
            icon="XCircleIcon"
            size="18"
            class="text-danger"
            v-b-tooltip
            title="NO PAID"
          />
          <feather-icon
            v-else-if="[2, 3, 4].includes(data.item.paid)"
            icon="AlertTriangleIcon"
            size="18"
            class="text-warning"
            v-b-tooltip
            :title="
              data.item.paid == 2
                ? 'PENDING APPROVAL'
                : data.item.paid == 3
                ? 'PENDING PENALTY'
                : 'PENDING CHARGEBACK'
            "
          />
          <feather-icon
            v-else
            icon="CheckCircleIcon"
            size="18"
            class="text-success"
            v-b-tooltip
            title="PAID"
          />
        </template>

        <template #cell(discounts)="data">
          <div
            v-if="
              (data.item.amount_chargeback == '0.00' &&
                data.item.amount_penalty == '0.00') ||
              (data.item.amount_chargeback == null &&
                data.item.amount_penalty == null)
            "
          >
            <b-badge variant="light-success "> WITHOUT DISCOUNTS </b-badge>
          </div>
          <div v-else style="gap: 2px">
            <div>
              <b-badge
                v-if="
                  data.item.dispute_response == 'rejected' ||
                  (data.item.dispute_response == 'approved' &&
                    data.item.status_penalty == 2 &&
                    isSentSubTab) ||
                  isDepartmentSubTab ||
                  (data.item.status_penalty == 2 &&
                    !isDepartmentSubTab &&
                    !isSentSubTab)
                "
                :variant="
                  (data.item.paid == 5 && data.item.status_penalty == 1) ||
                  data.item.cbdd_recovered == 4
                    ? 'light-success'
                    : data.item.paid == 4 || data.item.cbdd_recovered == 3
                    ? 'light-warning'
                    : 'light-danger'
                "
                :id="`tooltip-penalty${data.item.charge_back_id}`"
              >
                PENALTY: $ {{ data.item.amount_penalty | currency }}
                <b-tooltip
                  :target="`tooltip-penalty${data.item.charge_back_id}`"
                  triggers="hover"
                  placement="left"
                  v-if="data.item.tooltip_penalty"
                >
                  {{ data.item.tooltip_penalty.user }} <br />
                  {{ data.item.tooltip_penalty.date | myGlobalDay }}
                </b-tooltip>
              </b-badge>
            </div>
            <div v-if="data.item.amount_chargeback != '0.00'">
              <b-badge
                v-if="
                  isDepartmentSubTab ||
                  (isSentSubTab && data.item.dispute_response == 'rejected')
                "
                :variant="
                  data.item.paid == 5
                    ? 'light-success'
                    : data.item.paid == 3
                    ? 'light-warning'
                    : 'light-danger'
                "
                :id="`tooltip-chargeback${data.item.charge_back_id}`"
              >
                CHARGEBACK: $ {{ data.item.amount_chargeback | currency }}

                <b-tooltip
                  :target="`tooltip-chargeback${data.item.charge_back_id}`"
                  triggers="hover"
                  placement="left"
                  v-if="data.item.tooltip_charge"
                >
                  {{ data.item.tooltip_charge.user }} <br />
                  {{ data.item.tooltip_charge.date | myGlobalDay }}
                </b-tooltip>
              </b-badge>
            </div>
          </div>
        </template>

        <template #cell(tracking)="{ item }">
          <feather-icon
            class="mr-1 pointer text-primary"
            icon="ListIcon"
            size="18"
            v-b-tooltip
            title="Tracking"
            @click="modalShowFiles(item)"
          />
        </template>

        <template #cell(type_chargeback)="{ item }">
          <div class="d-flex flex-column" style="gap: 2px">
            <b-badge variant="light-info" v-if="item.dispute">
              DISPUTE
            </b-badge>
            <b-badge :variant="colorResponsable(item.responsable)" v-else>
              {{ nameResponsable(item.responsable) }}
            </b-badge>
          </div>
        </template>

        <template #cell(action)="data">
          <div class="d-flex flex-column" style="gap: 2px">
            <b-badge
              v-if="data.item.tooltip_penalty"
              variant="light-success"
              :id="`show-penalty${data.item.charge_back_id}`"
            >
              <feather-icon icon="CheckCircleIcon" size="18" />
              Penalty
              <b-tooltip
                :target="`show-penalty${data.item.charge_back_id}`"
                triggers="hover"
                placement="left"
                v-if="data.item.tooltip_penalty"
              >
                {{ data.item.tooltip_penalty.user }} <br />
                {{ data.item.tooltip_penalty.date | myGlobalDay }}
              </b-tooltip>
            </b-badge>
            <b-badge
              v-if="data.item.tooltip_charge"
              variant="light-success"
              :id="`show-charge${data.item.charge_back_id}`"
            >
              <feather-icon icon="CheckCircleIcon" size="18" />
              Chargeback
              <b-tooltip
                :target="`show-charge${data.item.charge_back_id}`"
                triggers="hover"
                placement="left"
                v-if="data.item.tooltip_charge"
              >
                {{ data.item.tooltip_charge.user }} <br />
                {{ data.item.tooltip_charge.date | myGlobalDay }}
              </b-tooltip>
            </b-badge>
          </div>
          <div
            v-if="
              !isManagement &&
              ((((isInProcess &&
                (isPaymentSubTab || isDepartmentSubTab || isRemoveSubTab)) ||
                (isSentSubTab && data.item.dispute_response == 'rejected')) &&
                data.item.paid != 5) ||
                (isRemoveSubTab && data.item.cbdd_recovered == 2))
            "
            class="d-flex flex-column"
            style="gap: 2px"
          >
            <b-badge
              variant="light-warning"
              class="clickable"
              v-if="
                ((isPaymentSubTab ||
                  isDepartmentSubTab ||
                  isRemoveSubTab ||
                  isSentSubTab) &&
                  data.item.status_penalty == 1 &&
                  (data.item.paid == 3 || data.item.paid == 1)) ||
                (isRemoveSubTab && data.item.cbdd_recovered == 2)
              "
              @click="modalPayment(data.item, 2)"
              v-b-tooltip.hover.top="'Pay'"
            >
              <feather-icon icon="AlertCircleIcon" size="18" />
              Penalty
            </b-badge>
            <b-badge
              variant="light-warning"
              class="clickable"
              @click="modalPayment(data.item, 1)"
              v-if="
                ((isPaymentSubTab || isDepartmentSubTab || isSentSubTab) &&
                  data.item.status_penalty == 1 &&
                  (data.item.paid == 4 || data.item.paid == 1)) ||
                (isPaymentSubTab && data.item.status_penalty == 2)
              "
              v-b-tooltip.hover.top="'Pay'"
            >
              <feather-icon icon="AlertCircleIcon" size="18" />
              Chargeback
            </b-badge>
          </div>
          <feather-icon
            v-if="
              (isManagement &&
                isRemoveSubTab &&
                data.item.paid == 1 &&
                data.item.status_penalty == 2) ||
              (isManagement &&
                data.item.paid == 2 &&
                data.item.status_penalty == 1) ||
              (isManagement &&
                isSentSubTab &&
                ((data.item.paid == 2 && data.item.status_penalty == 1) ||
                  (data.item.paid == 1 && data.item.status_penalty == 2)) &&
                data.item.dispute_response == 'approved')
            "
            class="mr-1 pointer text-success"
            icon="CheckCircleIcon"
            size="18"
            v-b-tooltip
            title="Validate Payment"
            @click="modalValidatePayment(data.item)"
          />
          <feather-icon
            v-if="isVisibleBtnAssignResponsible(data)"
            class="mr-1 pointer text-danger"
            icon="UserCheckIcon"
            size="18"
            v-b-tooltip
            title="Assing Responsible"
            @click="openAssignResponsibleModal(data.item)"
          />
          <template
            v-if="
              isManagement &&
              isSentSubTab &&
              data.item.dispute_response == 'pending'
            "
          >
            <feather-icon
              @click="
                openModalDisputeResponse('approved', data.item.charge_back_id)
              "
              class="mx-1 cursor-pointer text-primary"
              :icon="'ThumbsUpIcon'"
              size="20"
              v-b-tooltip
              title="Approve"
            />
            <feather-icon
              @click="
                openModalDisputeResponse('rejected', data.item.charge_back_id)
              "
              class="mx-1 cursor-pointer text-danger"
              :icon="'ThumbsDownIcon'"
              size="20"
              v-b-tooltip
              title="Reject"
            />
          </template>
          <template
            v-else-if="
              !isManagement &&
              isSentSubTab &&
              data.item.dispute_response == 'approved' &&
              data.item.paid == 1 &&
              data.item.status_penalty == 1
            "
          >
            <b-badge
              variant="light-warning"
              class="clickable"
              @click="modalPayment(data.item, 2)"
              v-b-tooltip.hover.top="'Pay'"
            >
              <feather-icon icon="AlertCircleIcon" size="18" />
              Penalty
            </b-badge>
          </template>
        </template>
      </b-table>
    </filter-slot>

    <charge-back-tracking-modal
      v-if="stateModalTracking"
      :chargeBackId="transaction_id"
      :leadName="leadName"
      @close="closeModalShowFiles"
    />

    <evidences-modal
      v-if="stateEvidencesModal"
      @close="closeModalEvidences"
      @reload="refreshTableChargebacks"
      :chargeBackData="charge_back_data"
    />

    <payment-modal
      v-if="statePaymentModal"
      @close="closePaymentModal"
      @reload="refreshTable"
      :chargeBack="chargeBackData"
      :payment="typePayment"
    />

    <validate-payment-modal
      v-if="stateValidatePaymentModal"
      @close="closeValidatePaymentModal"
      @reload="refreshTable"
      :chargeBack="chargeBackValidate"
    />

    <modal-dispute-response
      v-if="activeModalDisputeResponse"
      :isActive="activeModalDisputeResponse"
      :result="resultDispute"
      :charge_back_id="charge_back_id"
      @close="closeModalDisputeResponse"
      @refresh="$refs.chargeBack.refresh()"
    />

    <assign-responsible-modal
      v-if="stateAssignResponsibleModal"
      @close="closeAssignResponsibleModal"
      @reload="$refs.chargeBack.refresh()"
      :itemToEvidence="itemToEvidence"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import ChargeBackService from "@/views/commons/components/charge-back_v2/service/charge.back.service";
//Modals
import chargeBackFields from "@/views/commons/components/charge-back_v2/data/fields.charge.back";
import ChargeBackTrackingModal from "@/views/commons/components/charge-back_v2/view/modal/ChargeBackTrackingModal.vue";
import EvidencesModal from "@/views/commons/components/charge-back_v2/view/modal/EvidencesModal.vue";
import Timer from "@/views/commons/components/charge-back_v2/view/components/Timer.vue";
import FiltersData from "@/views/commons/components/charge-back_v2/data/filter.data";
import PaymentModal from "@/views/commons/components/charge-back_v2/view/modal/PaymentModal.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import ViewClientDetails from "@/views/ce-digital/sub-modules/financial/components/ViewClientDetails.vue";
import ModalDisputeResponse from "@/views/commons/components/charge-back_v2/view/modal/ModalDisputeResponse.vue";
import AssignResponsibleModal from "@/views/commons/components/charge-back_v2/view/modal/AssignResponsibleModal.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
export default {
  components: {
    ChargeBackTrackingModal,
    Timer,
    EvidencesModal,
    PaymentModal,
    FeatherIcon,
    ModalDisputeResponse,
    AssignResponsibleModal,
    ViewClientDetails,
    StatusAccount,
  },
  data() {
    return {
      items: [],
      fields: chargeBackFields,
      filter: FiltersData,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder:
          "Search by client name, department, reason code or merchant ...",
        model: "",
      },
      totalRows: 0,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      isBusy: false,
      searchInput: "",
      sortDesc: true,
      toPage: 0,
      sortBy: "time_upload_files",
      module: "",
      stateModalTracking: false,
      itemToEvidence: [],
      transaction_id: null,
      charge_back_data: null,
      stateEvidencesModal: false,
      filesType: null,
      statePaymentModal: false,
      currentClient: null,
      chargeBackData: null,
      stateValidatePaymentModal: false,
      chargeBackValidate: null,
      activeModalDisputeResponse: false,
      resultDispute: "",
      charge_back_id: null,
      leadName: "",
      stateAssignResponsibleModal: false,
      typePayment: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      getStatusRefreshTableChargeBack:
        "ChargeBackStoreV2/G_REFRESH_TABLE_CHARGE_BACK",
      client: "DebtSolutionClients/G_CLIENTS",
      totalDiscounts: "ChargeBackStoreV2/G_CHARGEBACK_TOTAL_DISCOUNTS",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    isInPending() {
      return this.$route.matched[2].meta.status == 1;
    },
    isInProcess() {
      return this.$route.matched[2].meta.status == 2;
    },
    isInCompleted() {
      return this.$route.matched[2].meta.status == 3;
    },
    isDisputeTab() {
      if (!this.$route.matched[3]) return false;
      const { routePending, routeObserved, routeSent } =
        this.$route.matched[3].meta;
      return [routePending, routeObserved, routeSent].includes(
        this.$route.name
      );
    },
    isRemoveSubTab() {
      return this.isInPending || this.isDisputeTab
        ? false
        : this.$route.matched[3]?.meta.status == 1;
    },
    isPaymentSubTab() {
      return this.isInPending || this.isDisputeTab
        ? false
        : this.$route.matched[3]?.meta.status == 2;
    },
    isDepartmentSubTab() {
      return this.isInPending || this.isDisputeTab
        ? false
        : this.$route.matched[3]?.meta.status == 3;
    },
    isPendingSubTab() {
      if (!this.$route.matched[3]) return false;
      return this.$route.name == this.$route.matched[3].meta.routePending;
    },
    isObservedSubTab() {
      if (!this.$route.matched[3]) return false;
      return this.$route.name == this.$route.matched[3].meta.routeObserved;
    },
    isSentSubTab() {
      if (!this.$route.matched[3]) return false;
      return this.$route.name == this.$route.matched[3].meta.routeSent;
    },
    responsibleSubTab() {
      if (this.$route.matched[2].meta.status == 1) {
        return null;
      }

      if (this.isDisputeTab) {
        return this.$route.matched[4].meta.status;
      }

      return this.$route.matched[3].meta.status;
    },

    isModule() {
      return this.$route.meta.module == 28 ? 11 : this.$route.meta.module;
    },
    program() {
      return this.convertoModuleToProgramC(this.isModule);
    },
    currentTab() {
      return this.isDisputeTab
        ? this.$route.matched[3].meta.status
        : this.$route.matched[2].meta.status;
    },
    isManagement() {
      return this.$route.matched[0].meta.module == 16;
    },
    isCreditExperts() {
      return this.$route.matched[0].meta.module == 23;
    },
    dashboardRouteName() {
      const routers = {
        3: "bussiness-dashboard-clients",
        4: "administration-dashboard",
        5: "debt-solution-dashboard",
        6: "credit-experts-dashboard",
        7: "boost-credit-dashboard",
        8: "tax-research-client-dashboard",
        10: "claim-department-dashboard",
        11: "specialists-client-dashboard",
        14: "bookeeping-dashboard",
        18: "quality-client-dashboard",
        16: "management-client-dashboard",
      };
      return routers[this.moduleId] || null;
    },
  },
  async created() {
    this.start();
  },
  async mounted() {
    await this.getStatusClient();
  },
  methods: {
    ...mapActions({
      refreshTableChargeBack: "ChargeBackStoreV2/A_REFRESH_TABLE_CHARGE_BACK",
      getStatusClient: "DebtSolutionZeroPayment/getStatusAccountAction",
      SET_CURRENT_CLIENT: "DebtSolutionClients/A_GET_CLIENTS",
      A_IN_PROCESS_AND_SOLUTION_CHARGEBACK_COUNTER:
        "ChargeBackStoreV2/A_IN_PROCESS_AND_SOLUTION_CHARGEBACK_COUNTER",
      getDiscounts: "ChargeBackStoreV2/A_CHARGEBACK_TOTAL_DISCOUNTS",
    }),

    async refreshTableChargebacks() {
      await this.$refs["chargeBack"].refresh();
    },

    isVisibleBtnAssignResponsible(data) {
      return (
        (this.moduleId !== 16 &&
          (this.isChief || this.isSupervisor || this.isCeo) &&
          this.isInPending &&
          data.item.time_assign_responsible <=
            this.limitTimeFile(data.item.limit_time_assign) &&
          data.item.time_expired_assign == 1) ||
        (this.moduleId == 16 &&
          this.isInPending &&
          (data.item.time_assign_responsible < 1 ||
            data.item.time_expired_assign == 2)) ||
        (this.moduleId == 16 &&
          this.isPendingSubTab &&
          data.item.total_approved < 1 &&
          data.item.total_rejected < 1)
      );
    },

    defineColorMerchant(merchant) {
      switch (merchant) {
        case 1:
          return "text-primary";
        case 2:
          return "text-info";
        case 3:
          return "text-warning";
        default:
          return "text-success";
      }
    },

    defineFileColor(pendings, approveds, rejecteds) {
      if (pendings == 0 && rejecteds == 0) {
        return approveds == 0 ? "secondary" : "success";
      }

      if (pendings > 0 && rejecteds > 0) {
        return this.isManagement ? "warning" : "danger";
      }

      if (pendings > 0 && rejecteds == 0) {
        return "warning";
      }

      if (pendings == 0 && rejecteds > 0) {
        return "danger";
      }
    },

    defineFileTooltip(pendings, approveds, rejecteds) {
      if (pendings == 0 && rejecteds == 0) {
        return approveds == 0
          ? "No files uploaded yet"
          : "All files were approved";
      }

      if (pendings > 0 && rejecteds > 0) {
        return `This chargeback contains ${
          this.isManagement ? "pending" : "rejected"
        } files`;
      }

      if (pendings > 0 && rejecteds == 0) {
        return "This chargeback contains pending files";
      }

      if (pendings == 0 && rejecteds > 0) {
        return "This chargeback contains rejected files";
      }
    },

    orderDir() {
      if (this.isDisputeTab) {
        this.sortBy = "time_expired";
        if (this.isManagement) {
          this.sortDesc = true;
          return;
        }
        this.sortDesc = false;
        return;
      }
      if (this.isRemoveSubTab || this.isPaymentSubTab) {
        if (this.isManagement) this.sortBy = "sol_man";
        else if (this.isModule) this.sortBy = "sol_pro";
        else this.sortBy = "created_at";
        this.sortDesc = true;
      }
    },

    resetAllFilters() {
      this.filter.map((fil) => {
        if (fil.type === "input-money") {
          fil.model = 0.0;
        } else {
          fil.model = null;
        }
      });
      this.filterPrincipal.model = null;
      this.$refs.chargeBack.refresh();
    },

    filterColumn(key, tab) {
      let field = this.fields.find((field) => field.key == `${key}`);
      field.visible = tab;
    },

    start() {
      this.orderDir();
      this.filter[5].visible = this.isManagement;
      this.filter[6].visible = this.isInProcess;
      const showPaid =
        this.isRemoveSubTab || this.isPaymentSubTab || this.isSentSubTab;
      const showAction = !this.isObservedSubTab && !this.isInCompleted;
      const showLimitPayment = this.isRemoveSubTab || this.isPaymentSubTab;
      this.filterColumn("type_chargeback", this.isInCompleted);
      if (this.isManagement) {
        this.filterColumn("department", true);
        this.filterColumn("time_upload_files", false);
        this.filterColumn("status", false);
        this.filterColumn("dispute_response", this.isSentSubTab);
        this.filterColumn("days", this.isSentSubTab);
        this.filterColumn("observation", false);
        this.filterColumn("files", this.isDisputeTab);
        this.filterColumn("paid", showPaid);
        this.filterColumn("status_penalty", showPaid);
        this.filterColumn("discounts", this.isInProcess || this.isSentSubTab);
        this.filterColumn("action", !this.isDepartmentSubTab && showAction);
        this.filterColumn("time_assign_responsible", this.isInPending);
        this.filterColumn("limit_time_payments", false);
      } else {
        this.filterColumn("time_upload_files", false);
        this.filterColumn("department", false);
        this.filterColumn("status", false);
        this.filterColumn("days", this.isSentSubTab);
        this.filterColumn("dispute_response", this.isSentSubTab);
        this.filterColumn("observation", false);
        this.filterColumn("files", this.isDisputeTab);
        this.filterColumn("discounts", this.isInProcess || this.isSentSubTab);
        this.filterColumn("paid", showPaid);
        this.filterColumn("status_penalty", showPaid);
        this.filterColumn("action", !this.isPendingSubTab && showAction);
        this.filterColumn("time_assign_responsible", this.isInPending);
        this.filterColumn("limit_time_payments", showLimitPayment);
      }
    },

    defineDisputeColor(pendings, approveds, rejecteds) {
      if (pendings == 0 && rejecteds == 0) {
        return approveds == 0 ? "secondary" : "success";
      }

      if (pendings > 0 && rejecteds > 0) {
        return this.isManagement ? "warning" : "danger";
      }

      if (pendings > 0 && rejecteds == 0) {
        return "warning";
      }

      if (pendings == 0 && rejecteds > 0) {
        return "danger";
      }
    },

    defineDisputeTooltip(pendings, approveds, rejecteds) {
      if (pendings == 0 && rejecteds == 0) {
        return approveds == 0 ? "No files uploaded" : "All files were approved";
      }

      if (pendings > 0 && rejecteds > 0) {
        return "Contains pending and rejected files";
      }

      if (pendings > 0 && rejecteds == 0) {
        return "Contains pending files";
      }

      if (pendings == 0 && rejecteds > 0) {
        return "Contains rejected files";
      }
    },

    refreshTable() {
      this.$refs["chargeBack"].refresh();
    },

    openAssignResponsibleModal(item) {
      this.itemToEvidence = item;
      this.stateAssignResponsibleModal = true;
    },

    closeAssignResponsibleModal() {
      this.stateAssignResponsibleModal = false;
    },

    modalShowFiles(item) {
      this.transaction_id = item.charge_back_id;
      this.leadName = item.client_name;
      this.stateModalTracking = true;
    },
    closeModalShowFiles() {
      this.stateModalTracking = false;
    },

    closePaymentModal() {
      this.statePaymentModal = false;
    },

    modalEvidences(item) {
      this.charge_back_data = item;
      this.stateEvidencesModal = true;
    },
    closeModalEvidences() {
      this.stateEvidencesModal = false;
    },

    closeValidatePaymentModal() {
      this.stateValidatePaymentModal = false;
    },

    openModalDisputeResponse(result, charge_back_id) {
      this.resultDispute = result;
      this.charge_back_id = charge_back_id;
      this.activeModalDisputeResponse = true;
    },

    closeModalDisputeResponse() {
      this.activeModalDisputeResponse = false;
    },

    changeExpired(item) {
      item.time_upload_files = 0;
      item.time_assign_responsible = 0;
      item.time_expired_payment = 0;
    },

    async modalPayment(clientInfo, typePayment) {
      await this.addPreloader();
      this.chargeBackData = clientInfo;
      this.currentClient = clientInfo;
      this.typePayment = typePayment;
      await this.SET_CURRENT_CLIENT({ id: clientInfo.client_account_id });
      this.statePaymentModal = true;
      await this.removePreloader();
    },

    async modalValidatePayment(item) {
      try {
        const response = await this.showConfirmSwal(
          "Are you sure to validate the payment"
        );
        if (response.isConfirmed) {
          this.addPreloader();
          const data = await ChargeBackService.validatePayment({
            charge_back_id: item.charge_back_id,
            create_by: this.currentUser.user_id,
            type_transaction: 15,
            modality_transaction_id: 6,
          });
          if (data.status === 200) {
            this.removePreloader();
            this.refreshTable();
            this.showSuccessSwal("Payment has been successfully validated");
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async chargeBackProvider(ctx) {
      try {
        const params = {
          orderby: ctx.sortBy,
          order: ctx.sortDesc ? "asc" : "desc",
          text_field: this.filterPrincipal.model,
          from: this.filter[0].model,
          to: this.filter[1].model,
          min_amount: this.filter[2].model == 0 ? null : this.filter[2].model,
          max_amount: this.filter[3].model == 0 ? null : this.filter[3].model,
          merchant_id: this.filter[4].model,
          status_recovered: this.filter[6].model,
          module_id: this.isManagement ? null : this.isModule,
          program_id: this.isManagement ? this.filter[5].model : this.program,
          status: this.currentTab,
          current_page: ctx.currentPage,
          responsible: this.isInProcess ? this.responsibleSubTab : null,
          dispute: this.isDisputeTab ? this.responsibleSubTab : null,
          per_page: ctx.perPage,
          completed: this.isInCompleted,
        };

        const { data, from, per_page, last_page, total, to } =
          await ChargeBackService.chargeBackSearch(params);

        data.map((el) => (el.date_passed -= parseInt(el.sundays_passed)));

        this.items = data.map((el) => {
          el.detail_charges = JSON.parse(el.detail_charges) ?? [];
          el.tooltip_penalty = el.detail_charges.find(
            (element) => element.type === 48
          );
          el.tooltip_charge = el.detail_charges.find(
            (element) => element.type === 47
          );
          return el;
        });
        this.startPage = from;
        this.paginate.perPage = per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = last_page;
        this.totalRows = total;
        this.toPage = to;
        return this.items || [];
      } catch (e) {
        console.log(e);
      }
    },

    limitTimeFile(time) {
      return time * 3600;
    },
    nameResponsable(name) {
      let refactor = {
        "remove chargeback": "REMOVE CHARGEBACK",
        client: "PAYMENT CLIENT",
        department: " DEPARTMENT",
      };
      return refactor[name];
    },
    colorResponsable(name) {
      let refactor = {
        "remove chargeback": "light-danger",
        client: "light-success",
        department: "light-primary",
      };
      return refactor[name];
    },
  },

  watch: {
    getStatusRefreshTableChargeBack(State) {
      if (State) {
        this.$refs["chargeBack"].refresh();
        this.refreshTableChargeBack(false);
      }
    },
  },
};
</script>
<style>
.bigger {
  width: 1.35em !important;
  height: 1.35em !important;
}

.pointer {
  cursor: pointer;
}

.tooltip-custom-class .tooltip-inner {
  max-width: 400px !important;
}
</style>
