<template>
  <b-modal
    v-model="uploadFileModal"
    title="Upload Evidence Files"
    title-tag="h3"
    modal-class="modal-primary"
    button-size="sm"
    ok-title="Ok"
    :hide-footer="files.length === 0"
    centered
    @hidden="actionOnHideUploadFileModal"
    size="lg"
  >
    <b-container>
      <b-row class="justify-content-md-left my-1">
        <b-col cols="12" sm="auto">
          <h3>{{ number_documents }} files required :</h3>
        </b-col>
        <template v-for="name_doc in name_documents">
          <b-col :key="name_doc.id" cols="12" sm="auto">
            <b-badge variant="primary badge-large" class="mb-1">{{
              name_doc.name
            }}</b-badge>
          </b-col>
        </template>
      </b-row>

      <drag-and-drop
        v-model="files"
        :files-array="files"
        :files-to-show="[]"
        :limitFilesChargeBack="number_documents"
      />
    </b-container>
    <validation-observer ref="responsible">
      <template v-if="false">
        <br />
        <div v-for="(file, index) in files" :key="index">
          <div>
            <label
              >File description
              <span class="text-primary">{{ file.name }}</span>
              (optional):</label
            >
            <b-form-group>
              <b-form-textarea
                v-model="file.description"
                placeholder="Enter Description..."
                type="text"
                required
                class="heightTextarea"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </div>
      </template>
    </validation-observer>
    <template #modal-footer="{ cancel }">
      <button-cancel @click="cancel()" />
      <b-button variant="success" @click="uploadFiles()">Upload</b-button>
    </template>
  </b-modal>
</template>

<script>
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue"
import { mapGetters, mapMutations } from "vuex"
import buttonSave from "@/views/commons/utilities/ButtonSave.vue"
import buttonCancel from "@/views/commons/utilities/ButtonCancel.vue"
import ChargeBackService from "@/views/commons/components/charge-back_v2/service/charge.back.service"

export default {
  components: {
    DragAndDrop,
    buttonSave,
    buttonCancel,
  },
  props: {
    itemToEvidence: null,
  },

  data() {
    return {
      uploadFileModal: false,
      files: [],
      number_documents: 5,
      name_documents: "",
    }
  },
  computed: {
    ...mapGetters({
      uploadFilesClaim: "DebtSolutionClients/G_UPLOAD_FILES_CLAIM",
      skin: "appConfig/skin",
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
    moduleId() {
      return this.$route.meta.module
    },
  },
  created() {
    this.uploadFileModal = true
    this.itemLocal = { ...this.itemToEvidence }
    if (this.uploadFilesClaim.length > 0) {
      this.files = this.uploadFilesClaim
    }

    // limit and name documents
    this.number_documents = this.itemLocal.number_documents
    this.name_documents = JSON.parse(this.itemLocal.name_documents)
  },
  methods: {
    ...mapMutations({
      showModalTaskToday: "DebtSolutionClients/SET_UPLOAD_FILES_CLAIM",
    }),
    actionOnHideUploadFileModal() {
      this.showModalTaskToday([])
      this.$emit("close")
    },
    openUploadFileModal() {
      this.uploadFileModal = true
    },

    deleteFile(content) {
      const index = this.currentFiles.indexOf(content)
      if (index > -1) this.currentFiles.splice(index, 1)
    },
    async uploadFiles() {
      this.addPreloader()
      try {
        const res = await this.$refs.responsible.validate()
        const descriptions = []
        if (res) {
          const formData = new FormData()
          this.files.forEach((file) => {
            formData.append("files[]", file, file.name)
            if (
              typeof file.description !== "string" ||
              !file.description.trim().length
            ) {
              descriptions.push("No Description")
            } else {
              descriptions.push(file.description)
            }
          })
          formData.append("charge_back_id", this.itemLocal.charge_back_id)
          formData.append("module_id", this.moduleId)
          formData.append("user_id", this.currentUser.user_id)
          formData.append("status", 1)
          formData.append("number_documents", this.number_documents)
          formData.append("obs", JSON.stringify(descriptions))
          const headers = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )
            }.bind(this),
          }

          const data = await ChargeBackService.addEvidence(formData, headers)

          if (data?.status === 204) {
            this.$emit("reload")
            this.close()
            this.showSuccessSwal()
          } else {
            this.showErrorSwal(data)
          }
        }
      } catch (e) {
        this.showErrorSwal(e.response.data.error, e.response.data.msg)
      } finally {
        this.removePreloader()
      }
    },
    close() {
      this.files = []
      this.$emit("close")
    },
  },
}
</script>
<style scoped>
.heightTextarea {
  height: 45px !important;
  resize: none;
}

.badge-large {
  font-size: 1.2rem;
}
</style>
