<template>
  <b-modal
    v-model="active"
    title-tag="h3"
    header-class="text-uppercase"
    :title-html="`<b>CHARGEBACK DISPUTE WAS ${result} </b>`"
    :header-bg-variant="modalColor"
    @hidden="closeMe"
    hide-footer
    no-close-on-backdrop
  >
    <DragAndDrop v-model="files" :files-array="files" :files-to-show="[]" />
    <div class="text-center">
      <b-form-group label="Did the client accept the penalty?">
        <b-form-radio-group
          :class="{ 'border-danger': error }"
          v-model="selected"
          :options="options"
        />
      </b-form-group>
      <b-button @click="save" :variant="modalColor" size="lg">
        <FeatherIcon icon="SaveIcon" size="20" />
        <b> SAVE</b>
      </b-button>
    </div>
  </b-modal>
</template>
<script>
//components
import DragAndDrop from "@/views/commons/utilities/DragAndDrop"
// service
import ChargeBackService from "@/views/commons/components/charge-back_v2/service/charge.back.service"
// vuex
import { mapGetters, mapActions } from "vuex"

export default {
  components: {
    DragAndDrop,
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    result: {
      // approved | rejected
      type: String,
      required: true,
    },
    charge_back_id: {
      type: Number,
      required: true,
    },
  },
  beforeMount() {
    this.openMe()
  },
  data() {
    return {
      active: false,
      selected: null,
      options: [
        { text: "YES", value: 1 },
        { text: "NO", value: 2 },
      ],
      files: [],
      error: false,
    }
  },
  methods: {
    ...mapActions({
      getDiscounts: "ChargeBackStoreV2/A_CHARGEBACK_TOTAL_DISCOUNTS",
      A_CHARGEBACK_COUNTERS: "ChargeBackStoreV2/A_CHARGEBACK_COUNTERS",
    }),
    openMe() {
      this.active = this.isActive
    },
    closeMe() {
      this.active = false
      this.$emit("close")
    },
    async save() {
      if (!this.isValid()) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Select a option to continue"
        )
        return
      }

      const formData = new FormData()
      this.files.forEach((file) => {
        formData.append("files[]", file, file.name)
      })

      formData.append("charge_back_id", this.charge_back_id)
      formData.append("dispute_response", this.result == "approved" ? 2 : 3)
      formData.append("user_id", this.currentUser.user_id)
      formData.append("status_penalty", this.selected)
      formData.append("module_id", this.moduleId)

      this.addPreloader()
      const { data, status } = await ChargeBackService.disputeResponse(formData)
      this.removePreloader()

      if (status == 204) {
        this.closeMe()
        this.A_CHARGEBACK_COUNTERS(this.moduleId)
        this.getDiscounts(this.moduleId)
        this.showSuccessSwal()
        this.$emit("refresh")
      } else {
        this.showErrorSwal(data)
      }
    },
    isValid() {
      return !(this.selected == null && this.result == "approved")
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    modalColor() {
      return this.result === "approved" ? "primary" : "danger"
    },
    moduleId() {
      return this.$route.matched[0].meta.module == 28 ? 11 : this.$route.matched[0].meta.module;
    },
  },
  watch: {
    selected(newVal, oldVal) {
      this.error = false
    },
  },
}
</script>
