<template>
  <b-badge variant="light-success" pill
    >{{ isLoading ? "Loading " : countDown }}
    <b-spinner
      v-if="isLoading"
      variant="success"
      label="Spinning"
      small
    ></b-spinner>
  </b-badge>
</template>

<script>
export default {
  name: "Timer",
  props: {
    time: {
      type: Number,
      required: true,
    },
    chargeBackId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      countDown: "",
      dataTime: this.time,
      isLoading: true,
    };
  },
  mounted() {
    this.startTimer();
    // timeout 1 second
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  },
  methods: {
    startTimer() {
      let interval = setInterval(() => {
        this.dataTime--;
        this.countDown = this.formatTime(this.dataTime);
        if (this.dataTime <= 0) {
          clearInterval(interval);
          this.$emit("expired");
        }
      }, 1000);
    },
    formatTime(time) {
      let hour = Math.floor(time / 3600);
      let minutes = Math.floor((time % 3600) / 60);
      let seconds = time % 60;
      return `${hour}:${minutes < 10 ? "0" + minutes : minutes}:${
        seconds < 10 ? "0" + seconds : seconds
      }`;
    },
  },
};
</script>
