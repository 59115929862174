<template>
  <b-modal
    v-model="onControl"
    title="EVIDENCE FILES"
    title-tag="h3"
    modal-class="modal-primary"
    ref="myModal"
    @hidden="close"
    size="xlg"
    hide-footer
  >
    <template #modal-title>
      <div class="d-flex justify-content-center align-items-center">
        <div>
          <h3 class="mb-0 text-white">EVIDENCES MODAL</h3>
        </div>
        <div v-if="!isManagement">
          <feather-icon
            icon="InfoIcon"
            size="24"
            class="ml-50 text-warning"
            cursor="help"
            v-b-tooltip.hover
            :title="`These files will begin to be reviewed by Management after ${chargeBackData.limit_time_files} hours of being assigned to a dispute or after clicking on the &quot;SENT TO MANAGEMENT&quot; button.`"
          >
          </feather-icon>
        </div>
      </div>
    </template>

    <div
      class="d-flex align-items-end justify-content-end"
      v-if="visibleBtnAdd"
    >
      <b-button
        variant="success"
        class="m-1"
        @click="modalAddEvidence(chargeBackData)"
      >
        <feather-icon icon="PlusIcon" size="15" class="mr-50 text-white" />ADD
      </b-button>
    </div>

    <b-table
      id="evidences-list"
      ref="evidences"
      no-border-collapse
      class="position-relative"
      :fields="visibleFields"
      show-empty
      no-provider-filtering
      sticky-header="50vh"
      primary-key="id"
      :items="getEvidences"
      :busy.sync="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(file)="data">
        <div class="d-flex justify-content-center">
          <p>
            <a
              :href="data.item.file"
              target="_blank"
              :title="data.item.file_name"
            >
              <amg-icon
                v-if="data.item.extension === 'docx'"
                class="cursor-pointer text-primary"
                icon="FileWordIcon"
                size="18"
              />
              <amg-icon
                v-else-if="data.item.extension === 'pdf'"
                class="cursor-pointer text-danger"
                icon="FilePdfIcon"
                size="18"
              />
              <amg-icon
                v-else-if="data.item.extension === 'xlsx'"
                class="cursor-pointer text-success"
                icon="FileExcelIcon"
                size="18"
              />
              <amg-icon
                v-else-if="
                  data.item.extension === 'pptx' ||
                  data.item.extension === 'ppt'
                "
                class="cursor-pointer text-warning"
                icon="FilePowerpointIcon"
                size="18"
              />
              <feather-icon
                v-else-if="
                  data.item.extension === 'jpg' || data.item.extension === 'png'
                "
                class="cursor-pointer text-info"
                icon="ImageIcon"
                size="18"
              />
              <tabler-icon
                v-else
                class="cursor-pointer text-dark"
                icon="FileDownloadIcon"
                size="18"
              />
            </a>
          </p>
        </div>
      </template>
      <template #cell(name_file)="data">
        {{ data.item.file_name }}
      </template>
      <template #cell(observation)="data">
        <span
          :class="data.item.observation == 'No Description' && 'text-danger'"
        >
          {{ data.item.observation }}
        </span>
      </template>
      <template #cell(module)="data">
        {{ data.item.module }}
      </template>
      <template #cell(createdBy)="data">
        {{ data.item.created_by_name }} <br />
        {{ data.item.created_at | myGlobalWithHour }}
      </template>
      <template #cell(status)="data">
        <b-badge :variant="defineColorStatus(data.item.status)">
          {{ data.item.status.toUpperCase() }}
        </b-badge>
      </template>

      <template #cell(actions)="data">
        <div class="d-flex justify-content-center">
          <feather-icon
            v-if="
              !isManagement && isPendingSubTab && data.item.status == 'pending'
            "
            icon="TrashIcon"
            size="15"
            class="text-danger mr-1 cursor-pointer"
            variant="danger"
            v-b-tooltip.hover.top="'Delete Evidence'"
            @click="deleteEvidence(data.item.file_id)"
          />
          <feather-icon
            v-if="data.item.status == 'rejected' && !isManagement"
            icon="EditIcon"
            size="15"
            class="text-info mr-1 cursor-pointer"
            variant="info"
            v-b-tooltip.hover.top="'Update rejected file'"
            @click="modalShowRejectedFile(data.item)"
          />
          <template
            v-if="
              data.item.status == 'pending' &&
              isManagement &&
              (isPendingSubTab || isObservedSubTab) &&
              statusTimeExpired
            "
          >
            <feather-icon
              v-if="data.item.status == 'pending'"
              icon="ThumbsUpIcon"
              size="15"
              class="text-success mr-1 cursor-pointer"
              variant="success"
              v-b-tooltip.hover.top="'Approve file'"
              @click="ChangeStatusFile(data.item, 'approved')"
            />
            <feather-icon
              v-if="data.item.status == 'pending'"
              icon="ThumbsDownIcon"
              size="15"
              class="text-danger mr-1 cursor-pointer"
              variant="danger"
              v-b-tooltip.hover.top="'Reject file'"
              @click="ChangeStatusFile(data.item, 'rejected')"
            />
          </template>
        </div>
      </template>
      <template #cell(tracking)="data">
        <feather-icon
          icon="ListIcon"
          size="15"
          class="text-primary cursor-pointer"
          variant="primary"
          v-b-tooltip.hover.top="'Show dispute tracking'"
          @click="modalShowFileTracking(data.item)"
        />
      </template>
    </b-table>
    <template>
      <div
        class="d-flex align-items-end justify-content-center"
        v-if="visibleBtnChangeStatus"
      >
        <b-button
          v-if="changeChargeBackStatus != null"
          @click="ChangeStatusDisputeChargeBack(changeChargeBackStatus)"
          class="m-1"
          :variant="
            changeChargeBackStatus == true
              ? 'success'
              : changeChargeBackStatus == false
              ? 'danger'
              : 'secondary'
          "
          :disabled="changeChargeBackStatus == null"
        >
          {{
            changeChargeBackStatus == true
              ? "Sent"
              : changeChargeBackStatus == false &&
                chargeBackData.dispute == "observed"
              ? "Notify Observations"
              : changeChargeBackStatus == false
              ? "Observed"
              : "Disable"
          }}
        </b-button>
      </div>
    </template>

    <template>
      <div
        class="d-flex align-items-end justify-content-center"
        v-if="canBeSendToManagement && !statusTimeExpired"
      >
        <b-button @click="sendToManagement" class="m-1" variant="success"
          >SEND TO MANAGEMENT</b-button
        >
      </div>
    </template>

    <add-evidence-modal
      v-if="stateModalUpload"
      @close="closeModalUploadFile"
      :itemToEvidence="itemToEvidence"
      @reload="refreshEvidences"
    />

    <charge-back-tracking-modal
      v-if="stateModalTracking"
      :chargeBackId="transaction_id"
      :fileId="fileId"
      :leadName="leadName"
      @close="closeModalShowFileTracking"
    />

    <update-rejected-file-modal
      v-if="stateModalRejectedFile"
      :chargeBack="chargeBackData"
      :rejectedFile="rejectedFile"
      @close="closeModalRejectedFile"
      @reload="refreshEvidences"
    />
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import ChargeBackService from "@/views/commons/components/charge-back_v2/service/charge.back.service"
import AddEvidenceModal from "@/views/commons/components/charge-back_v2/view/modal/AddEvidenceModal.vue"
import ChargeBackTrackingModal from "@/views/commons/components/charge-back_v2/view/modal/ChargeBackTrackingModal.vue"
import UpdateRejectedFileModal from "@/views/commons/components/charge-back_v2/view/modal/UpdateRejectedFIleModal.vue"
export default {
  components: {
    AddEvidenceModal,
    ChargeBackTrackingModal,
    UpdateRejectedFileModal,
  },
  props: {
    chargeBackData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      onControl: false,
      items: [],
      stateModalUpload: false,
      fields: [
        {
          key: "name_file",
          label: "name file",
          visible: true,
          thStyle: {
            width: "300px",
          },
        },
        {
          key: "file",
          label: "File",
          visible: true,
          thStyle: {
            textAlign: "center",
          },
        },
        {
          key: "status",
          label: "status",
          visible: true,
        },
        {
          key: "module",
          label: "Module",
          visible: true,
        },
        {
          key: "createdBy",
          label: "Created by",
          visible: true,
        },
        {
          key: "actions",
          label: "Actions",
          visible: true,
          class: "text-center",
        },
        {
          key: "tracking",
          label: "Tracking",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      isBusy: false,
      transaction_id: null,
      stateModalTracking: false,
      stateModalChangeStatusFile: false,
      stateModalRejectedFile: false,
      // isDispute: false,
      // fileType: null,
      fileId: null,
      rejectedFile: null,
      leadName: null,
    }
  },

  created() {
    this.onControl = true
    if (this.isDisputeTab) {
      this.fields.find((x) => x.key == "tracking").visible = true
    }
  },

  methods: {
    close() {
      this.$emit("close")
    },
    ...mapActions({
      A_CHARGEBACK_COUNTERS: "ChargeBackStoreV2/A_CHARGEBACK_COUNTERS",
    }),

    defineColorStatus(status) {
      if (status === "pending") {
        return "light-warning"
      } else if (status === "approved") {
        return "light-success"
      } else {
        return "light-danger"
      }
    },

    async getEvidences() {
      try {
        this.addPreloader()
        const data = await ChargeBackService.getEvidences({
          idchargeback: this.chargeBackData.charge_back_id,
          type: this.filesType,
        })
        this.items = data.data
        return this.items || []
      } catch (error) {
        this.showErrorSwal(`Error: ${error}`)
      } finally {
        this.removePreloader()
      }
    },

    async deleteEvidence(id) {
      try {
        const response = await this.showConfirmSwal(
          "Are you sure you want to delete evidence"
        )
        if (response.isConfirmed) {
          this.addPreloader()
          await ChargeBackService.deleteEvidence({
            id: id,
            user_id: this.currentUser.user_id,
          })
          this.$refs.evidences.refresh()
          this.showSuccessSwal("Evidence has been deleted successfully")
        }
      } catch (error) {
        this.showErrorSwal(error)
      } finally {
        this.removePreloader()
      }
    },

    async ChangeStatusDisputeChargeBack(status) {
      try {
        this.addPreloader()
        await ChargeBackService.changeStatusDisputeChargeBack({
          charge_back_id: this.chargeBackData.charge_back_id,
          dispute: status ? "sent" : "observed",
          user_id: this.currentUser.user_id,
          module_id: this.moduleId,
        })

        this.showGenericSuccessSwal("Charge back status changed successfully")
        await this.A_CHARGEBACK_COUNTERS(this.moduleId)
        this.$refs.evidences.refresh()
        this.$emit("reload")
      } catch (error) {
        this.showErrorSwal(error)
      } finally {
        this.close()
        this.removePreloader()
      }
    },

    async ChangeStatusFile(infoFile, newStatus) {
      this.$refs.myModal.$refs.content.removeAttribute("tabIndex")

      if (newStatus == "rejected") {
        const validateObservation = await this.$swal({
          title: "Your Observation",
          input: "textarea",
          showCancelButton: true,
          confirmButtonText: "Save",
          cancelButtonText: "Cancel",

          inputValidator: (observation) => {
            // If the value is valid, you must return undefined. Otherwise, a string
            return !observation ? "Please enter your observation" : undefined
          },
        })

        if (validateObservation.value) {
          try {
            this.addPreloader()
            await ChargeBackService.changeStatusFile({
              file_id: infoFile.file_id,
              status: newStatus,
              user_id: this.currentUser.user_id,
              module_id: infoFile.module_id,
              observation: validateObservation.value,
            })
            this.showGenericSuccessSwal("File status changed successfully")
            this.$refs.evidences.refresh()
            this.$emit("reload")
          } catch (error) {
            this.showErrorSwal(error)
          } finally {
            this.removePreloader()
          }
        }
      } else {
        const confirmChangeStateFile = await this.showConfirmSwal(
          "Are you sure?",
          "The status of the file will be changed"
        )
        if (confirmChangeStateFile.isConfirmed) {
          try {
            this.addPreloader()
            await ChargeBackService.changeStatusFile({
              file_id: infoFile.file_id,
              status: newStatus,
              user_id: this.currentUser.user_id,
              module_id: infoFile.module_id,
            })
            this.showGenericSuccessSwal("File status changed successfully")
            this.$refs.evidences.refresh()
            this.$emit("reload")
          } catch (error) {
            this.showErrorSwal(error)
          } finally {
            this.removePreloader()
          }
        }
      }
    },

    async sendToManagement() {
      const confirmChangeStateFile = await this.showConfirmSwal(
        "Are you sure you only want to send these files?",
        "Management will be able to start rejecting or approving your files."
      )
      if (confirmChangeStateFile.isConfirmed) {
        try {
          this.addPreloader()

          //service
          await ChargeBackService.departmentSendsFilesToManagement({
            charge_back_id: this.chargeBackData.charge_back_id,
            module_id: this.isModule,
            user_id: this.currentUser.user_id,
          })
          this.showGenericSuccessSwal({
            text: "Successfully sent to management",
          })
          await this.A_CHARGEBACK_COUNTERS(this.moduleId)
          this.close()
          this.$emit("reload")
        } catch (error) {
          this.showErrorSwal(error)
        } finally {
          this.removePreloader()
        }
      }
    },

    modalShowFileTracking(item) {
      this.fileId = item.file_id
      this.transaction_id = item.charge_back_id
      this.leadName = item?.client_name
      this.stateModalTracking = true
    },
    closeModalShowFileTracking() {
      this.stateModalTracking = false
    },

    refreshEvidences() {
      this.$refs.evidences.refresh()
      this.$emit("reload")
    },

    modalAddEvidence(item) {
      this.stateModalUpload = true
      this.itemToEvidence = item
    },

    closeModalUploadFile() {
      this.stateModalUpload = false
      this.$refs.evidences.refresh()
    },

    modalShowRejectedFile(item) {
      this.rejectedFile = item
      this.stateModalRejectedFile = true
    },
    closeModalRejectedFile() {
      this.stateModalRejectedFile = false
    },

    limitTimeFile(time) {
      return time * 3600
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module
    },
    isManagement() {
      return this.$route.matched[0].meta.module == 16
    },
    isModule() {
      return this.$route.meta.module
    },
    isDisputeTab() {
      return this.$route.matched[2].meta.status == 3
    },
    isProcessTab() {
      return this.$route.matched[2].meta.status == 1
    },
    isPendingSubTab() {
      return this.isPending ? false : this.$route.matched[4].meta.status == 1
    },
    isObservedSubTab() {
      return this.isPending ? false : this.$route.matched[4].meta.status == 2
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible)
    },
    visibleBtnChangeStatus() {
      return (
        this.isManagement &&
        (this.isPendingSubTab || this.isObservedSubTab) &&
        this.items.length > 0
      )
    },

    changeChargeBackStatus() {
      const filesArePending = this.items.some(
        (file) => file.status == "pending"
      )

      const canChangeToSentStatus = this.items.every(
        (file) => file.status === "approved"
      )

      const canChangeToObservedStatus =
        this.items.some((file) => file.status === "rejected") &&
        !filesArePending

      if (canChangeToSentStatus) return true
      else if (canChangeToObservedStatus) return false
      else return null
    },

    doesNotExceedTimeLimit() {
      return (
        this.chargeBackData.time_upload_files > 0 &&
        this.chargeBackData.time_upload_files <=
          this.limitTimeFile(this.chargeBackData.limit_time_files)
      )
    },
    canBeSendToManagement() {
      return (
        this.items.length > 0 &&
        this.isPendingSubTab &&
        !this.isManagement &&
        this.doesNotExceedTimeLimit
      )
    },
    statusTimeExpired() {
      return this.chargeBackData.time_expired.toLowerCase() == "expired"
    },
    visibleBtnAdd() {
      return (
        !this.isManagement &&
        this.items.length < this.chargeBackData.number_documents
      )
    },
  },
}
</script>

<style scoped>
.column-width {
  width: 400px !important;
}
.bigger {
  width: 1.5em;
  height: 1.5em;
}
</style>
