var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filter-slot',{attrs:{"filter":_vm.filter,"filter-principal":_vm.filterPrincipal,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage},on:{"reload":_vm.refreshTableChargebacks,"reset-all-filters":_vm.resetAllFilters}},[_c('b-table',{ref:"chargeBack",staticClass:"position-relative",attrs:{"slot":"table","id":"charge-back","fields":_vm.visibleFields,"show-empty":"","no-provider-filtering":"","sticky-header":"50vh","primary-key":"id","table-class":"text-nowrap","responsive":"sm","items":_vm.chargeBackProvider,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage,"filter":_vm.searchInput,"busy":_vm.isBusy},on:{"update:busy":function($event){_vm.isBusy=$event}},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(client_name)",fn:function(data){return [_c('b-row',[_c('b-col',[(_vm.isCreditExperts)?_c('view-client-details',{attrs:{"client-information":data.item}}):_c('router-link',{attrs:{"target":"_blank","to":{
                name: _vm.dashboardRouteName,
                params: { idClient: ("" + (data.item.client_account_id)) },
              }}},[_vm._v(" "+_vm._s(data.item.client_name)+" ")])],1)],1),_c('b-row',[_c('b-col',[_vm._v(" "+_vm._s(data.item.client_account)+" ")])],1),_c('b-row',[_c('b-col',[_c('status-account',{attrs:{"account":data.item,"text":true}})],1)],1)]}},{key:"cell(amount)",fn:function(data){return [_c('div',[_vm._v("ID: "+_vm._s(data.item.t_transaction_id))]),_c('div',[_vm._v("$ "+_vm._s(_vm._f("currency")(data.item.amount)))]),_c('div',[_vm._v(_vm._s(_vm._f("myGlobalDay")(data.item.transaction_date)))])]}},{key:"cell(merchant)",fn:function(data){return [_c('span',{class:_vm.defineColorMerchant(data.item.merchant_id)},[_vm._v(" "+_vm._s(data.item.merchant_name)+" ")]),_c('div',[_vm._v("AUTH CODE: "+_vm._s(data.item.auth_code))])]}},{key:"cell(reason_code)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.reason_code)+" "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(data.item.reason_code_description),expression:"data.item.reason_code_description",modifiers:{"hover":true,"top":true}}],staticClass:"text-primary",attrs:{"icon":"InfoIcon"}})]}},{key:"cell(time_assign_responsible)",fn:function(data){return [(
            data.item.time_assign_responsible > 0 &&
            data.item.time_assign_responsible <=
              _vm.limitTimeFile(data.item.limit_time_assign) &&
            data.item.time_expired_assign == 1
          )?_c('Timer',{attrs:{"time":data.item.time_assign_responsible,"chargeBackId":data.item.id},on:{"expired":function($event){return _vm.changeExpired(data.item)}}}):_c('b-badge',{attrs:{"variant":"light-danger","pill":""}},[_vm._v(" EXPIRED ")])]}},{key:"cell(time_upload_files)",fn:function(data){return [(
            data.item.time_upload_files > 0 &&
            data.item.time_upload_files <=
              _vm.limitTimeFile(data.item.limit_time_files) &&
            data.item.time_expired.toLowerCase() == 'pending'
          )?_c('Timer',{attrs:{"time":data.item.time_upload_files,"chargeBackId":data.item.id},on:{"expired":function($event){return _vm.changeExpired(data.item)}}}):_c('b-badge',{attrs:{"variant":"light-danger","pill":""}},[_vm._v(" EXPIRED ")])]}},{key:"cell(limit_time_payments)",fn:function(data){return [(
            data.item.limit_time_payments > 0 &&
            data.item.time_expired_payment == 1 &&
            data.item.paid != 2 &&
            data.item.paid != 5
          )?_c('Timer',{attrs:{"time":data.item.limit_time_payments,"chargeBackId":data.item.id},on:{"expired":function($event){return _vm.changeExpired(data.item)}}}):_c('b-badge',{attrs:{"variant":"light-danger","pill":""}},[_vm._v(" EXPIRED ")])]}},{key:"cell(department)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.department)+" ")]}},{key:"cell(created_at)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.user_name)+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("myGlobalWithHour")(data.item.created_at))+" ")]}},{key:"cell(files)",fn:function(data){return [_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
            _vm.defineFileTooltip(
              data.item.total_pending,
              data.item.total_approved,
              data.item.total_rejected
            )
          ),expression:"\n            defineFileTooltip(\n              data.item.total_pending,\n              data.item.total_approved,\n              data.item.total_rejected\n            )\n          ",modifiers:{"hover":true,"top":true}}],class:'pointer text-' +
            _vm.defineFileColor(
              data.item.total_pending,
              data.item.total_approved,
              data.item.total_rejected
            ),attrs:{"icon":"FileTextIcon","size":"18"},on:{"click":function($event){return _vm.modalEvidences(data.item)}}})]}},{key:"cell(status)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.status)+" ")]}},{key:"cell(dispute_response)",fn:function(ref){
            var item = ref.item;
            var value = ref.value;
return [_c('b-badge',{staticStyle:{"text-transform":"uppercase"},attrs:{"variant":value == 'pending'
              ? 'light-warning'
              : value == 'approved'
              ? 'light-success'
              : 'light-danger'}},[_vm._v(" "+_vm._s(item.dispute_response)+" ")])]}},{key:"cell(days)",fn:function(ref){
              var item = ref.item;
return [_c('b-badge',{attrs:{"variant":item.date_passed > 20
              ? 'light-danger'
              : item.date_passed > 10
              ? 'light-warning'
              : 'light-success'}},[(item.date_passed > 30)?_c('span',[_vm._v(" EXPIRED ")]):_c('span',[_vm._v(" "+_vm._s(item.date_passed)+" ")])])]}},{key:"cell(observation)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.observation)+" ")]}},{key:"cell(status_penalty)",fn:function(data){return [_c('b-badge',{attrs:{"variant":data.item.status_penalty == 1
              ? 'light-success'
              : data.item.status_penalty == 2
              ? 'light-danger'
              : 'light-warning'}},[_vm._v(_vm._s(data.item.status_penalty == 1 ? "ACCEPT" : data.item.status_penalty == 2 ? "DENIED" : "NOT ASSIGNED"))])]}},{key:"cell(paid)",fn:function(data){return [(data.item.paid == 1)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"text-danger",attrs:{"icon":"XCircleIcon","size":"18","title":"NO PAID"}}):([2, 3, 4].includes(data.item.paid))?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"text-warning",attrs:{"icon":"AlertTriangleIcon","size":"18","title":data.item.paid == 2
              ? 'PENDING APPROVAL'
              : data.item.paid == 3
              ? 'PENDING PENALTY'
              : 'PENDING CHARGEBACK'}}):_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"text-success",attrs:{"icon":"CheckCircleIcon","size":"18","title":"PAID"}})]}},{key:"cell(discounts)",fn:function(data){return [(
            (data.item.amount_chargeback == '0.00' &&
              data.item.amount_penalty == '0.00') ||
            (data.item.amount_chargeback == null &&
              data.item.amount_penalty == null)
          )?_c('div',[_c('b-badge',{attrs:{"variant":"light-success "}},[_vm._v(" WITHOUT DISCOUNTS ")])],1):_c('div',{staticStyle:{"gap":"2px"}},[_c('div',[(
                data.item.dispute_response == 'rejected' ||
                (data.item.dispute_response == 'approved' &&
                  data.item.status_penalty == 2 &&
                  _vm.isSentSubTab) ||
                _vm.isDepartmentSubTab ||
                (data.item.status_penalty == 2 &&
                  !_vm.isDepartmentSubTab &&
                  !_vm.isSentSubTab)
              )?_c('b-badge',{attrs:{"variant":(data.item.paid == 5 && data.item.status_penalty == 1) ||
                data.item.cbdd_recovered == 4
                  ? 'light-success'
                  : data.item.paid == 4 || data.item.cbdd_recovered == 3
                  ? 'light-warning'
                  : 'light-danger',"id":("tooltip-penalty" + (data.item.charge_back_id))}},[_vm._v(" PENALTY: $ "+_vm._s(_vm._f("currency")(data.item.amount_penalty))+" "),(data.item.tooltip_penalty)?_c('b-tooltip',{attrs:{"target":("tooltip-penalty" + (data.item.charge_back_id)),"triggers":"hover","placement":"left"}},[_vm._v(" "+_vm._s(data.item.tooltip_penalty.user)+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(data.item.tooltip_penalty.date))+" ")]):_vm._e()],1):_vm._e()],1),(data.item.amount_chargeback != '0.00')?_c('div',[(
                _vm.isDepartmentSubTab ||
                (_vm.isSentSubTab && data.item.dispute_response == 'rejected')
              )?_c('b-badge',{attrs:{"variant":data.item.paid == 5
                  ? 'light-success'
                  : data.item.paid == 3
                  ? 'light-warning'
                  : 'light-danger',"id":("tooltip-chargeback" + (data.item.charge_back_id))}},[_vm._v(" CHARGEBACK: $ "+_vm._s(_vm._f("currency")(data.item.amount_chargeback))+" "),(data.item.tooltip_charge)?_c('b-tooltip',{attrs:{"target":("tooltip-chargeback" + (data.item.charge_back_id)),"triggers":"hover","placement":"left"}},[_vm._v(" "+_vm._s(data.item.tooltip_charge.user)+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(data.item.tooltip_charge.date))+" ")]):_vm._e()],1):_vm._e()],1):_vm._e()])]}},{key:"cell(tracking)",fn:function(ref){
                  var item = ref.item;
return [_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"mr-1 pointer text-primary",attrs:{"icon":"ListIcon","size":"18","title":"Tracking"},on:{"click":function($event){return _vm.modalShowFiles(item)}}})]}},{key:"cell(type_chargeback)",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"2px"}},[(item.dispute)?_c('b-badge',{attrs:{"variant":"light-info"}},[_vm._v(" DISPUTE ")]):_c('b-badge',{attrs:{"variant":_vm.colorResponsable(item.responsable)}},[_vm._v(" "+_vm._s(_vm.nameResponsable(item.responsable))+" ")])],1)]}},{key:"cell(action)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"2px"}},[(data.item.tooltip_penalty)?_c('b-badge',{attrs:{"variant":"light-success","id":("show-penalty" + (data.item.charge_back_id))}},[_c('feather-icon',{attrs:{"icon":"CheckCircleIcon","size":"18"}}),_vm._v(" Penalty "),(data.item.tooltip_penalty)?_c('b-tooltip',{attrs:{"target":("show-penalty" + (data.item.charge_back_id)),"triggers":"hover","placement":"left"}},[_vm._v(" "+_vm._s(data.item.tooltip_penalty.user)+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(data.item.tooltip_penalty.date))+" ")]):_vm._e()],1):_vm._e(),(data.item.tooltip_charge)?_c('b-badge',{attrs:{"variant":"light-success","id":("show-charge" + (data.item.charge_back_id))}},[_c('feather-icon',{attrs:{"icon":"CheckCircleIcon","size":"18"}}),_vm._v(" Chargeback "),(data.item.tooltip_charge)?_c('b-tooltip',{attrs:{"target":("show-charge" + (data.item.charge_back_id)),"triggers":"hover","placement":"left"}},[_vm._v(" "+_vm._s(data.item.tooltip_charge.user)+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(data.item.tooltip_charge.date))+" ")]):_vm._e()],1):_vm._e()],1),(
            !_vm.isManagement &&
            ((((_vm.isInProcess &&
              (_vm.isPaymentSubTab || _vm.isDepartmentSubTab || _vm.isRemoveSubTab)) ||
              (_vm.isSentSubTab && data.item.dispute_response == 'rejected')) &&
              data.item.paid != 5) ||
              (_vm.isRemoveSubTab && data.item.cbdd_recovered == 2))
          )?_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"2px"}},[(
              ((_vm.isPaymentSubTab ||
                _vm.isDepartmentSubTab ||
                _vm.isRemoveSubTab ||
                _vm.isSentSubTab) &&
                data.item.status_penalty == 1 &&
                (data.item.paid == 3 || data.item.paid == 1)) ||
              (_vm.isRemoveSubTab && data.item.cbdd_recovered == 2)
            )?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Pay'),expression:"'Pay'",modifiers:{"hover":true,"top":true}}],staticClass:"clickable",attrs:{"variant":"light-warning"},on:{"click":function($event){return _vm.modalPayment(data.item, 2)}}},[_c('feather-icon',{attrs:{"icon":"AlertCircleIcon","size":"18"}}),_vm._v(" Penalty ")],1):_vm._e(),(
              ((_vm.isPaymentSubTab || _vm.isDepartmentSubTab || _vm.isSentSubTab) &&
                data.item.status_penalty == 1 &&
                (data.item.paid == 4 || data.item.paid == 1)) ||
              (_vm.isPaymentSubTab && data.item.status_penalty == 2)
            )?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Pay'),expression:"'Pay'",modifiers:{"hover":true,"top":true}}],staticClass:"clickable",attrs:{"variant":"light-warning"},on:{"click":function($event){return _vm.modalPayment(data.item, 1)}}},[_c('feather-icon',{attrs:{"icon":"AlertCircleIcon","size":"18"}}),_vm._v(" Chargeback ")],1):_vm._e()],1):_vm._e(),(
            (_vm.isManagement &&
              _vm.isRemoveSubTab &&
              data.item.paid == 1 &&
              data.item.status_penalty == 2) ||
            (_vm.isManagement &&
              data.item.paid == 2 &&
              data.item.status_penalty == 1) ||
            (_vm.isManagement &&
              _vm.isSentSubTab &&
              ((data.item.paid == 2 && data.item.status_penalty == 1) ||
                (data.item.paid == 1 && data.item.status_penalty == 2)) &&
              data.item.dispute_response == 'approved')
          )?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"mr-1 pointer text-success",attrs:{"icon":"CheckCircleIcon","size":"18","title":"Validate Payment"},on:{"click":function($event){return _vm.modalValidatePayment(data.item)}}}):_vm._e(),(_vm.isVisibleBtnAssignResponsible(data))?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"mr-1 pointer text-danger",attrs:{"icon":"UserCheckIcon","size":"18","title":"Assing Responsible"},on:{"click":function($event){return _vm.openAssignResponsibleModal(data.item)}}}):_vm._e(),(
            _vm.isManagement &&
            _vm.isSentSubTab &&
            data.item.dispute_response == 'pending'
          )?[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"mx-1 cursor-pointer text-primary",attrs:{"icon":'ThumbsUpIcon',"size":"20","title":"Approve"},on:{"click":function($event){return _vm.openModalDisputeResponse('approved', data.item.charge_back_id)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"mx-1 cursor-pointer text-danger",attrs:{"icon":'ThumbsDownIcon',"size":"20","title":"Reject"},on:{"click":function($event){return _vm.openModalDisputeResponse('rejected', data.item.charge_back_id)}}})]:(
            !_vm.isManagement &&
            _vm.isSentSubTab &&
            data.item.dispute_response == 'approved' &&
            data.item.paid == 1 &&
            data.item.status_penalty == 1
          )?[_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Pay'),expression:"'Pay'",modifiers:{"hover":true,"top":true}}],staticClass:"clickable",attrs:{"variant":"light-warning"},on:{"click":function($event){return _vm.modalPayment(data.item, 2)}}},[_c('feather-icon',{attrs:{"icon":"AlertCircleIcon","size":"18"}}),_vm._v(" Penalty ")],1)]:_vm._e()]}}])})],1),(_vm.stateModalTracking)?_c('charge-back-tracking-modal',{attrs:{"chargeBackId":_vm.transaction_id,"leadName":_vm.leadName},on:{"close":_vm.closeModalShowFiles}}):_vm._e(),(_vm.stateEvidencesModal)?_c('evidences-modal',{attrs:{"chargeBackData":_vm.charge_back_data},on:{"close":_vm.closeModalEvidences,"reload":_vm.refreshTableChargebacks}}):_vm._e(),(_vm.statePaymentModal)?_c('payment-modal',{attrs:{"chargeBack":_vm.chargeBackData,"payment":_vm.typePayment},on:{"close":_vm.closePaymentModal,"reload":_vm.refreshTable}}):_vm._e(),(_vm.stateValidatePaymentModal)?_c('validate-payment-modal',{attrs:{"chargeBack":_vm.chargeBackValidate},on:{"close":_vm.closeValidatePaymentModal,"reload":_vm.refreshTable}}):_vm._e(),(_vm.activeModalDisputeResponse)?_c('modal-dispute-response',{attrs:{"isActive":_vm.activeModalDisputeResponse,"result":_vm.resultDispute,"charge_back_id":_vm.charge_back_id},on:{"close":_vm.closeModalDisputeResponse,"refresh":function($event){return _vm.$refs.chargeBack.refresh()}}}):_vm._e(),(_vm.stateAssignResponsibleModal)?_c('assign-responsible-modal',{attrs:{"itemToEvidence":_vm.itemToEvidence},on:{"close":_vm.closeAssignResponsibleModal,"reload":function($event){return _vm.$refs.chargeBack.refresh()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }