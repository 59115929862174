export default [
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
  },
  {
    type: "input-money",
    margin: true,
    showLabel: true,
    label: "Min Ammount:",
    cols: 6,
    moneyLength: 10,
    visible: true,
    model: null,
  },
  {
    type: "input-money",
    margin: true,
    showLabel: true,
    label: "Max Amount:",
    moneyLength: 10,
    cols: 6,
    visible: true,
    model: null,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Merchant",
    model: null,
    options: [
      { id: 1, text: "AMG LLC(EMS DATA)" },
      { id: 2, text: "AMG BUSINESS INC(EMS DATA)" },
      { id: 3, text: "AMIG INC(CHASE)" },
      { id: 4, text: "AMG BUSINESS LLC(WELLS FARGO)" },
    ],
    reduce: "id",
    selectText: "text",
    cols: 12,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Departament",
    model: null,
    options: [
      { id: 1, text: "Business" },
      { id: 2, text: "Boost Credit" },
      { id: 3, text: "Credit Experts" },
      { id: 4, text: "Debt Solution" },
      { id: 5, text: "Tax Research" },
      { id: 6, text: "Court Info" },
      { id: 7, text: "Specialist" },
      { id: 8, text: "KeyBook" },
      { id: 9, text: "Paragon" },
    ],
    reduce: "id",
    selectText: "text",
    cols: 12,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Status Recovered",
    model: null,
    options: [
      { id: 1, text: "No" },
      { id: 2, text: "Unrecovered" },
      { id: 3, text: "Recovered" },
    ],
    reduce: "id",
    selectText: "text",
    cols: 12,
    visible: true,
  },
]
