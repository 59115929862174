<template>
  <b-modal
    v-model="onControl"
    title="Assign Chargeback Responsible"
    title-tag="h3"
    modal-class="modal-primary"
    button-size="sm"
    ok-title="OK"
    centered
    @hidden="close"
    size="lg"
  >
    <validation-observer ref="responsible">
      <validation-provider
        v-slot="{ errors }"
        name="responsible"
        :rules="{ required: true }"
      >
        <b-form-group>
          <b-input-group>
            <b-input-group-prepend class="w-25">
              <b-input-group-text class="w-100">
                <span>SELECT RESPONSIBLE</span>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-select
              v-model="responsible"
              :options="
                moduleId == 16 && isPendingSubTab
                  ? responsibleOptions.filter((option) =>
                      [null, 3].includes(option.value)
                    )
                  : responsibleOptions
              "
              class="select-icon-none"
              :state="errors.length > 0 ? false : null"
            />
          </b-input-group>
        </b-form-group>
      </validation-provider>

      <validation-provider
        v-if="responsible === 1 || responsible === 2"
        v-slot="{ errors }"
        name="penalty"
        :rules="{ required: true }"
      >
        <div class="d-flex align-items-center">
          <h5 class="my-1">Did the client accept penalty?</h5>
          <b-form-radio-group
            v-model="penaltyAccepted"
            :options="penaltyOptions"
            value-field="item"
            text-field="name"
            class="ml-1"
          />
        </div>
        <div class="text-danger" v-if="errors[0]">
          {{ errorMsg }}
        </div>
      </validation-provider>
    </validation-observer>
    <template #modal-footer>
      <b-button
        variant="success"
        @click="save()"
        :disabled="responsible === null"
        >Save</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import chargeBackV2Service from "@/views/commons/components/charge-back_v2/service/charge.back.service.js"
import { mapGetters, mapActions } from "vuex"

export default {
  components: {},
  props: {
    itemToEvidence: null,
  },
  data() {
    return {
      onControl: false,
      responsible: null,
      responsibleOptions: [
        { value: null, text: "SELECT RESPONSIBLE", disabled: true },
        { value: 1, text: "CLIENT REMOVE CHARGEBACK" },
        { value: 2, text: "PAYMENT CLIENT" },
        { value: 3, text: "DEPARTMENT" },
        { value: 4, text: "DISPUTE" },
      ],
      penaltyAccepted: null,
      penaltyOptions: [
        { item: 1, name: "YES" },
        { item: 2, name: "NO" },
      ],
      errorMsg: "Please, choose an option",
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module == 28 ? 11 : this.$route.matched[0].meta.module;
    },
    isSolutionTab() {
      return this.$route.matched[2].meta.status == 2
    },
    isInProcessTab() {
      return this.$route.matched[2].meta.status == 1
    },

    isPendingSubTab() {
      return this.isInProcessTab || this.isSolutionTab
        ? false
        : this.$route.matched[3].meta.status == 1
    },
  },
  created() {
    this.onControl = true
  },
  methods: {
    ...mapActions({
      getDiscounts: "ChargeBackStoreV2/A_CHARGEBACK_TOTAL_DISCOUNTS",
      A_CHARGEBACK_COUNTERS: "ChargeBackStoreV2/A_CHARGEBACK_COUNTERS",
    }),
    async save() {
      try {
        const res = await this.$refs.responsible.validate()
        if (res) {
          const response = await this.showConfirmSwal()
          if (!response.isConfirmed) {
            return
          }
          const formData = new FormData()
          formData.append("charge_back_id", this.itemToEvidence.charge_back_id)
          formData.append("isDispute", this.responsible == 4)
          formData.append(
            "client_account_id",
            this.itemToEvidence.client_account_id
          )
          formData.append("program_id", this.itemToEvidence.program_id)
          formData.append("module_id", this.moduleId)
          formData.append("user_id", this.currentUser.user_id)
          formData.append(
            "responsible",
            this.responsible !== 4 ? this.responsible : null
          )
          formData.append(
            "client_accept_pay",
            this.responsible == 1 || this.responsible == 2
              ? this.penaltyAccepted
              : null
          )
          formData.append("status_penalty", this.penaltyAccepted)
          this.addPreloader()
          const data = await chargeBackV2Service.assignResponsible(formData)
          if (data.status === 204) {
            Promise.all([
              this.A_CHARGEBACK_COUNTERS(this.moduleId),
              this.getDiscounts(this.moduleId),
            ])

            this.$emit("reload")
            this.close()
            this.showSuccessSwal()
          } else {
            this.showErrorSwal(data)
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.removePreloader()
      }
    },
    close() {
      this.$emit("close")
    },
  },
}
</script>
