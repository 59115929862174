<template>
  <b-modal
    v-model="onControl"
    title="UPDATE REJECTED FILE"
    title-tag="h3"
    modal-class="modal-primary"
    ok-title="OK"
    :hide-footer="files.length === 0"
    centered
    @hidden="close"
    size="lg"
  >
    <drag-and-drop
      v-model="files"
      :files-array="files"
      :files-to-show="[]"
      :limitFilesChargeBack="1"
    />
    <template #modal-footer>
      <button-cancel @click="close" />
      <b-button variant="success" @click="uploadFile">Upload</b-button>
    </template>
  </b-modal>
</template>

<script>
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue"
import { mapGetters, mapActions, mapMutations } from "vuex"
import buttonSave from "@/views/commons/utilities/ButtonSave.vue"
import buttonCancel from "@/views/commons/utilities/ButtonCancel.vue"
import ChargeBackService from "@/views/commons/components/charge-back_v2/service/charge.back.service"

export default {
  components: {
    DragAndDrop,
    buttonSave,
    buttonCancel,
  },
  props: {
    chargeBack: {
      type: Object,
      required: true,
    },
    rejectedFile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      onControl: false,
      files: [],
      isUpdatingRejectedFile: true,
    }
  },
  computed: {
    ...mapGetters({
      uploadFilesClaim: "DebtSolutionClients/G_UPLOAD_FILES_CLAIM",
      skin: "appConfig/skin",
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module
    },
  },
  created() {
    this.onControl = true
  },
  methods: {
    ...mapMutations({
      showModalTaskToday: "DebtSolutionClients/SET_UPLOAD_FILES_CLAIM",
    }),
    ...mapActions({
      A_CHARGEBACK_COUNTERS: "ChargeBackStoreV2/A_CHARGEBACK_COUNTERS",
    }),
    close() {
      this.showModalTaskToday([])
      this.$emit("close")
    },
    async uploadFile() {
      try {
        const formData = new FormData()
        formData.append("new_file", this.files[0])
        formData.append("id", this.rejectedFile.charge_back_id)
        formData.append("file_id", this.rejectedFile.file_id)
        formData.append("user_id", this.currentUser.user_id)
        formData.append("client_account", this.chargeBack.client_account_id)

        this.addPreloader()
        const data = await ChargeBackService.updateRejectedFile(formData)

        if (data.status === 200) {
          this.showSuccessSwal("File updated successfully")
          await this.A_CHARGEBACK_COUNTERS(this.moduleId)
          this.$emit("reload")
          this.close()
        }
      } catch (error) {
        this.showErrorSwal(error)
      } finally {
        this.removePreloader()
      }
    },
  },
}
</script>
